<template>
	<div>
		<div class="businessCharacteristics" v-if="windowWidth>=1200">
			<div class="bc_title">
				<div class="bc_title_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">{{$t('serviceItems.businessCharacteristics.title')}}</div>
				<!-- <div class="bc_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">BUSINESS FEATURES</div> -->
			</div>
			
			<div class="bc_content" v-for="(item,index) in $t('serviceItems.businessCharacteristics.list')" :key="index">
				<div class="bc_ct_left">
					<div class="bc_ct_lf_title">{{item.title}}</div>
					<div class="bc_ct_lf_content">{{item.content}}</div>
				</div>
				<div style="width: 30%;">
					<el-image style="width: 100%;height: 200px;" :src="item.image" :fit="'cover'"></el-image>
				</div>
			</div>
		</div>
		
		<div class="businessCharacteristics2" v-if="windowWidth<=1199">
			<div class="bc_title2">
				<div class="bc_title_cn2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">{{$t('serviceItems.businessCharacteristics.title')}}</div>
				<!-- <div class="bc_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">BUSINESS FEATURES</div> -->
			</div>
			
			<div class="bc_content2" v-for="(item,index) in $t('serviceItems.businessCharacteristics.list')" :key="index">
				<div class="bc_ct_left2">
					<div class="bc_ct_lf_title2">{{item.title}}</div>
					<div class="bc_ct_lf_content2">{{item.content}}</div>
				</div>
				<div style="">
					<el-image style="width: 100%;height: 200px;" :src="item.image" :fit="'cover'"></el-image>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				list:[{
					title:'海外交易',
					content:'海外原厂库存，各大代理现货，支持线上、线下交易提供人名币、美金的结款结算方式。',
					image:require('@/assets/img/overseasTransactions.png')
				},
				{
					title:'中转仓库',
					content:'在香港，深圳设立物流中转仓库，协助客户解决物流难题。',
					image:require('@/assets/img/storehouse.jpg')
				},
				{
					title:'军用科技',
					content:'高端军民 FPGA，CPLD，ADC，DAC，CONTROLLERS，DRIVERS，MEMORY，PMIC等种类，为你提供全球货源。',
					image:require('@/assets/img/warIndustry.png')
				},
				{
					title:'现货、期货资源',
					content:'集合欧美亚全球现货、期货资源，为您搜寻紧缺偏冷门停产器件，解决您的研发、生产短缺、小批量物件难题。',
					image:require('@/assets/img/spotGoods.png')
				},
				{
					title:'BOM需求',
					content:'根据客户和市场需求我们常备通用物料现货库存，TI、ADI、MAXIM、MINI、ATMEL等常用品牌一站式解决客户的BOM表需求。',
					image:require('@/assets/img/BOM.png')
				},
				{
					title:'库存代销',
					content:'提供全新原装电子库存代销，我们有1000多家贸易和最终用户合作伙伴，加之我们灵敏的市场触觉和成熟的销售渠道，帮您调剂消化库存压力盘活资金。',
					image:require('@/assets/img/stock.jpg')
				}]
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.businessCharacteristics{
			width: 100%;
			padding: 80px 200px 60px 200px;
			position: relative;
			z-index: 9;
			
			.bc_title{
				text-align: center;
				color: #333;
				
				.bc_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.bc_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
			.bc_content{
				display: flex;
				width: 100%;
				padding: 40px 0;
				border-bottom: 1px solid #cfd2d9;
				margin-top: 50px;
				
				.bc_ct_left{
					width: 70%;
					
					.bc_ct_lf_title{
						font-size: 23px;
						font-weight: bold;
						color: #1074bc;
					}
					
					.bc_ct_lf_content{
						font-size: 16px;
						line-height: 3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.businessCharacteristics{
			width: 100%;
			padding: 80px 200px 60px 200px;
			position: relative;
			z-index: 9;
			
			.bc_title{
				text-align: center;
				color: #333;
				
				.bc_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.bc_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
			.bc_content{
				display: flex;
				width: 100%;
				padding: 40px 0;
				border-bottom: 1px solid #cfd2d9;
				margin-top: 50px;
				
				.bc_ct_left{
					width: 70%;
					
					.bc_ct_lf_title{
						font-size: 23px;
						font-weight: bold;
						color: #1074bc;
					}
					
					.bc_ct_lf_content{
						font-size: 16px;
						line-height: 3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.businessCharacteristics{
			width: 100%;
			padding: 80px 100px 60px 100px;
			position: relative;
			z-index: 9;
			
			.bc_title{
				text-align: center;
				color: #333;
				
				.bc_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.bc_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
			.bc_content{
				display: flex;
				width: 100%;
				padding: 40px 0;
				border-bottom: 1px solid #cfd2d9;
				margin-top: 50px;
				
				.bc_ct_left{
					width: 70%;
					
					.bc_ct_lf_title{
						font-size: 23px;
						font-weight: bold;
						color: #1074bc;
					}
					
					.bc_ct_lf_content{
						font-size: 16px;
						line-height: 3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.businessCharacteristics{
			width: 100%;
			padding: 80px 60px 60px 60px;
			position: relative;
			z-index: 9;
			
			.bc_title{
				text-align: center;
				color: #333;
				
				.bc_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.bc_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
			.bc_content{
				display: flex;
				width: 100%;
				padding: 40px 0;
				border-bottom: 1px solid #cfd2d9;
				margin-top: 50px;
				
				.bc_ct_left{
					width: 70%;
					
					.bc_ct_lf_title{
						font-size: 23px;
						font-weight: bold;
						color: #1074bc;
					}
					
					.bc_ct_lf_content{
						font-size: 16px;
						line-height: 3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		
			.businessCharacteristics2{
				width: 100%;
				padding: 40px;
				position: relative;
				z-index: 9;
				
				.bc_title2{
					text-align: center;
					color: #333;
					
					.bc_title_en2{
						color: #333;
						font-size: 24px;
						line-height: 1.4;
						font-family:BigJohn;
					}
					
					.bc_title_cn2{
						font-size: 32px;
						font-weight: bold;
						font-family: zhenyan;
					}
				}
				
				.bc_content2{
					width: 100%;
					padding: 20px 0;
					border-bottom: 1px solid #cfd2d9;
					margin-top: 20px;
					
					.bc_ct_left2{
						width: 100%;
						
						.bc_ct_lf_title2{
							font-size: 23px;
							font-weight: bold;
							color: #1074bc;
						}
						
						.bc_ct_lf_content2{
							font-size: 16px;
							line-height: 2;
							padding-top: 20px;
						}
					}
				}
			}
		
	}
	
	@media only screen and (max-width:767px) {
		
			.businessCharacteristics2{
				width: 100%;
				padding: 40px;
				position: relative;
				z-index: 9;
				
				.bc_title2{
					text-align: center;
					color: #333;
					
					.bc_title_en2{
						color: #333;
						font-size: 24px;
						line-height: 1.4;
						font-family:BigJohn;
					}
					
					.bc_title_cn2{
						font-size: 32px;
						font-weight: bold;
						font-family: zhenyan;
					}
				}
				
				.bc_content2{
					width: 100%;
					padding: 20px 0;
					border-bottom: 1px solid #cfd2d9;
					margin-top: 20px;
					
					.bc_ct_left2{
						width: 100%;
						
						.bc_ct_lf_title2{
							font-size: 23px;
							font-weight: bold;
							color: #1074bc;
						}
						
						.bc_ct_lf_content2{
							font-size: 16px;
							line-height: 2;
							padding-top: 20px;
						}
					}
				}
			}
		
	}
</style>