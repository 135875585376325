<template>
	<div class="QualityManagement">
		<div class="qm_title">
			<div class="qm_title_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">{{$t('serviceItems.qualityManagement.title')}}</div>
			<!-- <div class="qm_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">QUALITY ASSURANCE</div> -->
		</div>
		
		<div style="text-align: center;padding-top: 50px;">
			<el-image style="width: 60%;" :src="require('@/assets/img/ISO.png')" :fit="'cover'"></el-image>
		</div>
		
		<div style="color: #333;font-size: 16px;line-height: 2;text-indent:33px;padding-top: 60px;">
			{{$t('serviceItems.qualityManagement.content1')}}
		</div>
		
		<div style="color: #333;font-size: 16px;line-height: 2;text-indent:33px;padding-top: 60px;">
			{{$t('serviceItems.qualityManagement.content2')}}
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.QualityManagement{
			width: 100%;
			padding: 80px 200px 60px 200px;
			position: relative;
			z-index: 9;
			
			.qm_title{
				text-align: center;
				color: #333;
				
				.qm_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.qm_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
		}
		
	}
	
	@media only screen and (max-width:1920px) {
		.QualityManagement{
			width: 100%;
			padding: 80px 200px 60px 200px;
			position: relative;
			z-index: 9;
			
			.qm_title{
				text-align: center;
				color: #333;
				
				.qm_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.qm_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
		}
		
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.QualityManagement{
			width: 100%;
			padding: 80px 100px 60px 100px;
			position: relative;
			z-index: 9;
			
			.qm_title{
				text-align: center;
				color: #333;
				
				.qm_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.qm_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
		}
		
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.QualityManagement{
			width: 100%;
			padding: 80px 60px 60px 60px;
			position: relative;
			z-index: 9;
			
			.qm_title{
				text-align: center;
				color: #333;
				
				.qm_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.qm_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
		}
		
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		
			.QualityManagement{
				width: 100%;
				padding:40px;
				position: relative;
				z-index: 9;
				
				.qm_title{
					text-align: center;
					color: #333;
					
					.qm_title_en{
						color: #333;
						font-size: 24px;
						line-height: 1.4;
						font-family:BigJohn;
					}
					
					.qm_title_cn{
						font-size: 32px;
						font-weight: bold;
						font-family: zhenyan;
					}
				}
				
			}
			
		
	}
	
	@media only screen and (max-width:767px) {
		
			.QualityManagement{
				width: 100%;
				padding: 40px;
				position: relative;
				z-index: 9;
				
				.qm_title{
					text-align: center;
					color: #333;
					
					.qm_title_en{
						color: #333;
						font-size: 24px;
						line-height: 1.4;
						font-family:BigJohn;
					}
					
					.qm_title_cn{
						font-size: 32px;
						font-weight: bold;
						font-family: zhenyan;
					}
				}
				
			}
			
		
	}
</style>