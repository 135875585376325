<template>
	<div>
		<div class="serviceTrade" v-if="windowWidth>=1200">
			<div class="st_title">
				<div class="st_title_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">{{$t('serviceItems.serviceTrade.title')}}</div>
				<!-- <div class="st_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">BUSINESS FEATURES</div> -->
			</div>
			
			<div class="st_content" v-for="(item,index) in $t('serviceItems.serviceTrade.list')" :key="index">
				<div class="st_ct_left">
					<div class="st_ct_lf_title">{{item.title}}</div>
					<div class="st_ct_lf_content">{{item.content}}</div>
				</div>
				<div style="width: 30%;">
					<el-image style="width: 100%;height: 200px;" :src="item.image" :fit="'cover'"></el-image>
				</div>
			</div>
		</div>
		
		<div class="serviceTrade2" v-if="windowWidth<=1199">
			<div class="st_title2">
				<div class="st_title_cn2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">{{$t('serviceItems.serviceTrade.title')}}</div>
				<!-- <div class="st_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">BUSINESS FEATURES</div> -->
			</div>
			
			<div class="st_content2" v-for="(item,index) in $t('serviceItems.serviceTrade.list')" :key="index">
				<div class="st_ct_left2">
					<div class="st_ct_lf_title2">{{item.title}}</div>
					<div class="st_ct_lf_content2">{{item.content}}</div>
				</div>
				<div>
					<el-image style="width: 100%;height: 200px;" :src="item.image" :fit="'cover'"></el-image>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				list:[{
					title:'新能源',
					content:'风能、光伏太阳能、LED 驱动、陶瓷材料、导热材料、超级电容等。',
					image:require('@/assets/img/newEnergy.png')
				},
				{
					title:'汽车电子',
					content:'影音娱乐、全景倒车系统、TUNER、GPS、车联网等。',
					image:require('@/assets/img/AutomotiveElectronics.png')
				},
				{
					title:'电力电表',
					content:'智能电表、电力载波抄表系统、计量芯片，光耦等。',
					image:require('@/assets/img/ElectricPowerMeter.png')
				},
				{
					title:'安防监控',
					content:'DVR、IP-CAMERA、智能监控。',
					image:require('@/assets/img/SecurityMonitoring.png')
				},
				{
					title:'军用科技',
					content:'航空、航天、兵器、卫星导航、石油勘探等。',
					image:require('@/assets/img/warIndustry.png')
				},
				{
					title:'射频微波',
					content:'无线通信（直放站、基站、RRU、WLAN）、WIFI、CMMB、ISM（工业、科学、医疗）等。',
					image:require('@/assets/img/RFMicrowave.png')
				},
				{
					title:'物联网',
					content:'RFID、专网通信、通信模块(M2M)、智能交通、智能家居。',
					image:require('@/assets/img/InternetThings.png')
				},
				{
					title:'工业自动化',
					content:'RFID、专网通信、通信模块(M2M)、智能交通、智能家居。',
					image:require('@/assets/img/industrialAutomation.png')
				}]
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.serviceTrade{
			width: 100%;
			padding: 80px 200px 60px 200px;
			position: relative;
			z-index: 9;
			
			.st_title{
				text-align: center;
				color: #333;
				
				.st_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.st_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
			.st_content{
				display: flex;
				width: 100%;
				padding: 40px 0;
				border-bottom: 1px solid #cfd2d9;
				margin-top: 50px;
				
				.st_ct_left{
					width: 70%;
					
					.st_ct_lf_title{
						font-size: 23px;
						font-weight: bold;
						color: #1074bc;
					}
					
					.st_ct_lf_content{
						font-size: 16px;
						line-height: 3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.serviceTrade{
			width: 100%;
			padding: 80px 200px 60px 200px;
			position: relative;
			z-index: 9;
			
			.st_title{
				text-align: center;
				color: #333;
				
				.st_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.st_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
			.st_content{
				display: flex;
				width: 100%;
				padding: 40px 0;
				border-bottom: 1px solid #cfd2d9;
				margin-top: 50px;
				
				.st_ct_left{
					width: 70%;
					
					.st_ct_lf_title{
						font-size: 23px;
						font-weight: bold;
						color: #1074bc;
					}
					
					.st_ct_lf_content{
						font-size: 16px;
						line-height: 3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.serviceTrade{
			width: 100%;
			padding: 80px 100px 60px 100px;
			position: relative;
			z-index: 9;
			
			.st_title{
				text-align: center;
				color: #333;
				
				.st_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.st_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
			.st_content{
				display: flex;
				width: 100%;
				padding: 40px 0;
				border-bottom: 1px solid #cfd2d9;
				margin-top: 50px;
				
				.st_ct_left{
					width: 70%;
					
					.st_ct_lf_title{
						font-size: 23px;
						font-weight: bold;
						color: #1074bc;
					}
					
					.st_ct_lf_content{
						font-size: 16px;
						line-height: 3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.serviceTrade{
			width: 100%;
			padding: 80px 60px 60px 60px;
			position: relative;
			z-index: 9;
			
			.st_title{
				text-align: center;
				color: #333;
				
				.st_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.st_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
			
			.st_content{
				display: flex;
				width: 100%;
				padding: 40px 0;
				border-bottom: 1px solid #cfd2d9;
				margin-top: 50px;
				
				.st_ct_left{
					width: 70%;
					
					.st_ct_lf_title{
						font-size: 23px;
						font-weight: bold;
						color: #1074bc;
					}
					
					.st_ct_lf_content{
						font-size: 16px;
						line-height: 3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		
			.serviceTrade2{
				width: 100%;
				padding: 40px;
				position: relative;
				z-index: 9;
				
				.st_title2{
					text-align: center;
					color: #333;
					
					.st_title_en2{
						color: #333;
						font-size: 24px;
						line-height: 1.4;
						font-family:BigJohn;
					}
					
					.st_title_cn2{
						font-size: 32px;
						font-weight: bold;
						font-family: zhenyan;
					}
				}
				
				.st_content2{
					width: 100%;
					padding: 20px 0;
					border-bottom: 1px solid #cfd2d9;
					margin-top: 20px;
					
					.st_ct_left2{
						width: 100%;
						
						.st_ct_lf_title2{
							font-size: 23px;
							font-weight: bold;
							color: #1074bc;
						}
						
						.st_ct_lf_content2{
							font-size: 16px;
							line-height: 2;
							padding-top: 20px;
						}
					}
				}
			}
		
	}
	
	@media only screen and (max-width:767px) {
		
			.serviceTrade2{
				width: 100%;
				padding: 40px;
				position: relative;
				z-index: 9;
				
				.st_title2{
					text-align: center;
					color: #333;
					
					.st_title_en2{
						color: #333;
						font-size: 24px;
						line-height: 1.4;
						font-family:BigJohn;
					}
					
					.st_title_cn2{
						font-size: 32px;
						font-weight: bold;
						font-family: zhenyan;
					}
				}
				
				.st_content2{
					width: 100%;
					padding: 20px 0;
					border-bottom: 1px solid #cfd2d9;
					margin-top: 20px;
					
					.st_ct_left2{
						width: 100%;
						
						.st_ct_lf_title2{
							font-size: 23px;
							font-weight: bold;
							color: #1074bc;
						}
						
						.st_ct_lf_content2{
							font-size: 16px;
							line-height: 2;
							padding-top: 20px;
						}
					}
				}
			}
		
	}
</style>