<template>
	<div>
		<!-- 引入top组件 -->
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
		
		<!-- 引入子组件top组件 -->
		<subpage-top :title="$t('serviceItems.title')" :breadcrumbText="$t('serviceItems.title')" :image="image" :list="$t('serviceItems.topList')" @anchorPoint="anchorPoint"></subpage-top>
		
		<!-- 业务特点 -->
		<div v-if="anchorPointIndex==0">
			<business-characteristics :windowWidth="windowWidth" :windowHeight="windowHeight"></business-characteristics>
		</div>
		
		<!-- 品质管理体系 -->
		<div v-if="anchorPointIndex==1">
			<quality-management></quality-management>
		</div>
		
		<!-- 服务行业 -->
		<div v-if="anchorPointIndex==2">
			<service-trade :windowWidth="windowWidth" :windowHeight="windowHeight"></service-trade>
		</div>
		
		<!-- 产品类别 -->
		<div v-if="anchorPointIndex==3">
			<product-category></product-category>
		</div>
		
		<!-- 引入底部组件 -->
		<div v-if="windowWidth>=1200">
			<bottom></bottom>
		</div>
		
		<right></right>
	</div>
</template>

<script>
	import top from '@/components/top/top.vue'; //引入top组件
	import subpageTop from '@/components/subpageTop/index.vue'; //引入子组件top组件
	import businessCharacteristics from './components/businessCharacteristics.vue'; //引入优势分销模块
	import QualityManagement from './components/QualityManagement.vue'; //引入品质管理体系模块
	import serviceTrade from './components/serviceTrade.vue'; //引入服务行业模块
	import productCategory from './components/productCategory.vue'; //引入产品类别模块
	import bottom from '@/components/bottom/index.vue'; //引入bottom组件
	import right from '@/components/right/right.vue'; //引入bottom组件
	import {
		changeKeywords,
		changeDescription,
		changeTitle
	} from '@/utils/brower.js'
	export default {
		components: {
			top,
			subpageTop,
			businessCharacteristics,
			QualityManagement,
			serviceTrade,
			productCategory,
			bottom,
			right
		},
		data() {
			return {
				topList: [{
						id: 1,
						name: '服务项目'
					},
					{
						id: 2,
						name: '芯航国际ISD品质管理体系'
					},
					{
						id: 3,
						name: '服务行业'
					},
					{
						id: 4,
						name: '产品类别'
					}
				],
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
				image: 'http://img601.yun300.cn/repository/image/9d5c9931-ad41-4a00-b0fb-e8279cb89495.jpg?tenantId=252768&viewType=1',
				anchorPointIndex:0,
			}
		},
		
		created() {
			this.anchorPointIndex=this.$route.query.anchorPointIndex ? this.$route.query.anchorPointIndex : 0 ;
			this.$i18n.locale = this.$store.state.language;
			if (this.$i18n.locale == 'cn') {
				// document.title = '芯航国际-产品中心'
			} else {
				// document.title = 'xxintek-Product center'
			}
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		watch:{
			anchorPointIndex1(val){
				this.anchorPointIndex = val
			},
			//双向绑定语言切换
			'$i18n.locale'(val) {
				if (this.$i18n.locale == 'cn') {
					// document.title = '芯航国际-产品中心'
					changeTitle('深圳市芯航国际电子有限公司 官网-成为中国最值得信赖的IC元器件科技贸易服务商 芯片代理_ic代理 _芯片配单_电子元器件_半导体_军工')
					changeKeywords('芯片代理,ic代理,芯片配单,电子元器件,半导体,军工,芯航国际,IC,元器件,芯片,芯片贸易,芯片采购,芯片搜索')
					changeDescription('芯片代理,ic代理 ,芯片配单,电子元器件,半导体,军工,深圳市芯航国际电子有限公司专业代理分销ADI,TI,ALTERA,XILINX等国际知名半导体产品，业务涉及CPLD/FPGA各种中高低端及现场可编程逻辑技术、高效的DSP数字信号及ARM处理器、,FLASH存储及智能电源管理技术等领域。公司业务主要覆盖汽车电子,工业控制,电力电子,通讯网络,航空航天,物联网,医疗几大行业。')
				} else {
					// document.title = 'xxintek-Product center'
					changeTitle('Shenzhen Xxintek International Electronics Co., Ltd. Official Website - Becoming the Most Trusted IC Component Technology Trade Service Provider Chip Agent in China_ IC Proxy_ Chip allocation_ Electronic components_ Semiconductor_ Military Industry')
					changeKeywords('Chip agent, IC agent, chip ordering, electronic components, semiconductors, military industry, Xxintek International, IC, components, chips, chip trade, chip procurement, chip search')
					changeDescription("Chip agent, IC agent, chip ordering, electronic components, semiconductors, military industry, Shenzhen Xxintek International Electronics Co., Ltd. specializes in the distribution of internationally renowned semiconductor products such as ADI, TI, Altera, XILINX, etc. Our business involves various fields such as CPLD/FPGA mid to low end and on-site programmable logic technology, efficient DSP digital signals and ARM processors, FLASH storage and intelligent power management technology. The company's business mainly covers several major industries, including automotive electronics, industrial control, power electronics, communication networks, aerospace, Internet of Things, and healthcare.")
				}
			}
		},
		
		beforeCreate() {
			if(this.$i18n.locale=='cn'){
				changeTitle('深圳市芯航国际电子有限公司 官网-成为中国最值得信赖的IC元器件科技贸易服务商 芯片代理_ic代理 _芯片配单_电子元器件_半导体_军工')
				changeKeywords('芯片代理,ic代理,芯片配单,电子元器件,半导体,军工,芯航国际,IC,元器件,芯片,芯片贸易,芯片采购,芯片搜索')
				changeDescription('芯片代理,ic代理 ,芯片配单,电子元器件,半导体,军工,深圳市芯航国际电子有限公司专业代理分销ADI,TI,ALTERA,XILINX等国际知名半导体产品，业务涉及CPLD/FPGA各种中高低端及现场可编程逻辑技术、高效的DSP数字信号及ARM处理器、,FLASH存储及智能电源管理技术等领域。公司业务主要覆盖汽车电子,工业控制,电力电子,通讯网络,航空航天,物联网,医疗几大行业。')
			}else{
				changeTitle('Shenzhen Xxintek International Electronics Co., Ltd. Official Website - Becoming the Most Trusted IC Component Technology Trade Service Provider Chip Agent in China_ IC Proxy_ Chip allocation_ Electronic components_ Semiconductor_ Military Industry')
				changeKeywords('Chip agent, IC agent, chip ordering, electronic components, semiconductors, military industry, Xxintek International, IC, components, chips, chip trade, chip procurement, chip search')
				changeDescription("Chip agent, IC agent, chip ordering, electronic components, semiconductors, military industry, Shenzhen Xxintek International Electronics Co., Ltd. specializes in the distribution of internationally renowned semiconductor products such as ADI, TI, Altera, XILINX, etc. Our business involves various fields such as CPLD/FPGA mid to low end and on-site programmable logic technology, efficient DSP digital signals and ARM processors, FLASH storage and intelligent power management technology. The company's business mainly covers several major industries, including automotive electronics, industrial control, power electronics, communication networks, aerospace, Internet of Things, and healthcare.")
			}
		},
		
		computed:{
			anchorPointIndex1(){
				return this.$store.state.anchorPointIndex;
			}
		},
		
		methods:{
			//--------------------切换到指定位置----------------
			anchorPoint:function(index){
				this.anchorPointIndex=index;
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>