<template>
	<div class="productCategory">
		<div class="pc_title">
			<div class="pc_title_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">{{$t('serviceItems.productCategory.title')}}</div>
			<!-- <div class="pc_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">SERVICE INDUSTRY</div> -->
		</div>
		
		<div style="display: flex;padding-top: 50px;text-align: center;">
			<div style="width: 100%;" v-for="(item,index) in $t('serviceItems.productCategory.list')" :key="index">
				<div style="line-height: 3;background-image: linear-gradient(to right, #0168b5 , #fff);font-size: 24px;color: #fff;padding-left: 20px;margin-right: 20px;">{{item.class}}</div>
				<div v-for="(item2,index2) in item.data" :key="index2">
					<div style="padding-top: 20px;font-size: 16px;">{{item2.name}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list:[{
					class:'元器件组成',
					data:[{
						name:'IC (Integrated Circuits)集成电路'
					},
					{
						name:'resistors电阻'
					},
					{
						name:'capacitors电容'
					},
					{
						name:'diodes二极管'
					},
					{
						name:'transistors三极管'
					},
					{
						name:'switches开关'
					},
					{
						name:'connectors连接器'
					},
					{
						name:'sockets插座'
					}]
				}]
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
		
	}
</script>

<style lang="less" scoped>
	@media only screen and (min-width:1920px) {
		.productCategory{
			width: 100%;
			padding: 80px 200px 60px 200px;
			position: relative;
			z-index: 9;
			
			.pc_title{
				text-align: center;
				color: #333;
				
				.pc_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.pc_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.productCategory{
			width: 100%;
			padding: 80px 200px 60px 200px;
			position: relative;
			z-index: 9;
			
			.pc_title{
				text-align: center;
				color: #333;
				
				.pc_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.pc_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.productCategory{
			width: 100%;
			padding: 80px 100px 60px 100px;
			position: relative;
			z-index: 9;
			
			.pc_title{
				text-align: center;
				color: #333;
				
				.pc_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.pc_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.productCategory{
			width: 100%;
			padding: 80px 60px 60px 60px;
			position: relative;
			z-index: 9;
			
			.pc_title{
				text-align: center;
				color: #333;
				
				.pc_title_en{
					color: #333;
					font-size: 24px;
					line-height: 1.4;
					font-family:BigJohn;
				}
				
				.pc_title_cn{
					font-size: 32px;
					font-weight: bold;
					font-family: zhenyan;
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		
			.productCategory{
				width: 100%;
				padding: 40px;
				position: relative;
				z-index: 9;
				
				.pc_title{
					text-align: center;
					color: #333;
					
					.pc_title_en{
						color: #333;
						font-size: 24px;
						line-height: 1.4;
						font-family:BigJohn;
					}
					
					.pc_title_cn{
						font-size: 32px;
						font-weight: bold;
						font-family: zhenyan;
					}
				}
			}
		
	}
	
	@media only screen and (max-width:767px) {
		
			.productCategory{
				width: 100%;
				padding: 40px;
				position: relative;
				z-index: 9;
				
				.pc_title{
					text-align: center;
					color: #333;
					
					.pc_title_en{
						color: #333;
						font-size: 24px;
						line-height: 1.4;
						font-family:BigJohn;
					}
					
					.pc_title_cn{
						font-size: 32px;
						font-weight: bold;
						font-family: zhenyan;
					}
				}
			}
		
	}
</style>